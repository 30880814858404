<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="subtitle">
          <p>“助力唐山乡村振兴帮扶行动”正式启动——祝贺祥业科技集团与唐山市政府签订合作协议！</p>
          <p>发表时间:2023-01-07 15:03</p>
        </div>
        <div class="video-box">
          <img
              src="../../assets/image/enterpriseNews/tangshan/img1.jpg"
              alt=""
              style="width:900px;height:500px;"
            />
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              2023年1月7日，北京唐山企业商会、华夏董氏实业集团“助力唐山乡村振兴帮扶行动”启动仪式在唐山举行，主办方为华夏董氏实业集团。会场气氛高涨，热情洋溢，全球唐商联盟、北京唐山企业商会，央企、国家慈善机构等，以及数十家在京企业参加。全国政协常委、唐山市政府等领导给予高度重视，出席并讲话。祥业科技集团受邀参加。
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <!-- <img
              src="../../assets/image/enterpriseNews/Housewarming/img1.png"
              alt=""
            /> -->
            <img
              src="../../assets/image/enterpriseNews/tangshan/img2.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="describe describe1">
          <div class="bg">
            <span>
              启动仪式现场，祥业科技控股集团董事长与唐山市丰南区政府领导，进行了公共计算云产业示范基地项目签约。
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <!-- <img
              src="../../assets/image/enterpriseNews/Housewarming/img1.png"
              alt=""
            /> -->
            <img
              src="../../assets/image/enterpriseNews/tangshan/img3.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="describe describe1" style="height:200px">
          <div class="bg" style="height:160px">
            <span>
                祥业科技控股集团有限公司计划在丰南区投建公共计算产业示范基地，基地包括公共计算产业中心和大数据实训基地，项目投产后实现年均利税约9700万，解决就业3700人。
                作为唐山在京企业，祥业科技集团立足于自身品牌和技术，愿为唐山乡村振兴尽心尽力！为祖国科技发展竭尽所能！
            </span>
          </div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        
        <!-- <div class="textdescribe">
          <span
            >新的起点，新的起航，我们团结一心，奋发昂扬，坚持“以客户为中心，以奋斗者为本，以价值为纲”的核心价值观，为客户提供更加优质的产品与技术服务，为员工营造更加光明的发展前景，为公司书写更加华丽的篇章~</span
          >
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {

};
</script>
<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      padding: 30px 0 58px 0;
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #aad4ee;
      height: 220px;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #f4effd;
        height: 180px;
        color: #8735e4;
        font-size: 18px;
        padding: 40px 48px 0 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          margin: 22px 0 15px 0;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0;
        }
        img:nth-child(3) {
          margin: 0;
        }
      }
    }
    .bg-title {
      height: 28px;
      margin: 84px 0 60px 0;
      img{
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>